import "./App.css";
// import Canada from "./app/canada/Canada";
import ExploreSection from "./app/exploreSection/ExploreSection";
// import Footer from "./app/footer/Footer";
import EmailSignupForm from './component/emailSignupForm/EmailSignupForm';
import France from "./app/france/France";
import Germany from "./app/germany/Germany";
import Header from "./app/header/Header";
import UnitedKingdom from "./app/unitedKingdom/UnitedKingdom";
import China from "./app/china/China";
import Turkey from "./app/turkey/Turkey";
// import Switzerland from "./app/switzerland/Switzerland";
import UnitedStates from "./app/unitedStates/UnitedStates";
import Austria from "./app/austria/Austria";
import Japan from "./app/japan/Japan";
// import NewZealand from "./app/newZealand/NewZealand";
// import Italy from "./app/italy/Italy";
// import Slovenia from "./app/slovenia/Slovenia";

function App() {
  return (
    <div>
      <Header />
      <ExploreSection />
      <EmailSignupForm />
      <UnitedKingdom />
      <Germany />
      <UnitedStates />
      <Japan />
      <Austria />
      <France />
      {/* <NewZealand /> */}
      <Turkey />
      <China />
      {/* <Italy /> */}
      {/* <Slovenia/> */}
      {/* <Switzerland />
      
      <Canada /> */}
      {/* <Footer /> */}
    </div>
  );
}

export default App;
