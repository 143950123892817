const unitedStatesData = [
  {
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/bostonSpringfield.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/UnitedStates/bostonSpringfield.webp",
    title: "B&A: Boston - Springfield",
    info: "Boston - Springfield, Mass., USA",
    path: "/bostonAlbanySpringfield"
  },{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/longIslandRailroad.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/UnitedStates/longIslandRailroad.webp",
    title: "Long Island Rail Road",
    info: "New York City - Hicksville, NY",
    path: "/longIslandRailroad",
  },{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/mountShastaLine.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/UnitedStates/mountShastaLine.webp",
    title: "Mount Shasta Line",
    info: "Dunsmuir, CA - Klamath Falls, OR",
    path: "/mountShastaLine",
  },{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/soldierSummit.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/UnitedStates/soldierSummit.webp",
    title: "Soldier Summit",
    info: "Provo - Helper, UT, USA",
    path: "/soldierSummit",
  },{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/BO_MountainSubdivision.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/UnitedStates/BO_MountainSubdivision.webp",
    title: "B&O Mountain Subdivision",
    info: "Cumberland, MD - Grafton, WV",
    path: "/mountainSubdivisionBnO",
  },{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/arizonaDivide.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/UnitedStates/arizonaDivide.webp",
    title: "Arizona Divide",
    info: "Winslow - Williams, Arizona, USA",
    path: "/arizonaDivide"
  }
  // ,{
  //   image: "https://tswassets.blob.core.windows.net/tsc/tileImages/necWashingtonDC.jpg",
  //   webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/UnitedStates/necWashingtonDC.webp",
  //   title: "NEC Washington DC",
  //   info: "Baltimore, MD - Washington D.C.",
  //   path: "/washingtonDCNec"
  // }
];

export default unitedStatesData;
