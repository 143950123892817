const japanData = [
  {
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/tokyoCommuterKeihinTohokuUtsunomiya.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/Japan/tokyoCommuterKeihinTohokuUtsunomiya.webp",
    title: "Tokyo Commuter: Keihin–Tohoku & Utsunomiya Lines",
    info: "",
    path: "/tokyoCommuterKeihinTohokuUtsunomiya",
  },{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/tohokuHighSpeed.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/Japan/tohokuHighSpeed.webp",
    title: "Tōhoku High Speed & Main Line",
    info: "Kitakami, Japan - Morioka, Japan",
    path: "/tohokuHighSpeed",
  }
];

export default japanData;
