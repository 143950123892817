const austriaData = [
  {
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/drautalbahn.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/Austria/drautalbahn.webp",
    title: "Drautalbahn",
    info: "Klagenfurt - Spittal Millstättersee, Austria",
    path: "/drautalbahn",
  },{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/holzkirchenW%C3%B6rgl.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/Austria/holzkirchenW%C3%B6rgl.webp",
    title: "Holzkirchen - Wörgl",
    info: "Holzkirchen, Germany - Wörgl, Austria",
    path: "/holzkirchenWorgl",
  },{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/salzburgWels.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/Austria/salzburgWels.webp",
    title: "Salzburg - Wels",
    info: "Salzburg - Wels, Austria",
    path: "/salzburgWels"
  },{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/salzburgSchwarzachStVeit.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/Austria/salzburgSchwarzachStVeit.webp",
    title: "Salzburg - Schwarzach-St. Veit",
    info: "Salzburg - Schwarzach St Veit, Austria",
    path: "/salzburgSchwarzachStVeit"
  },{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/salzburgRosenheim.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/Austria/salzburgRosenheim.webp",
    title: "Salzburg - Rosenheim",
    info: "Rosenheim, Germany - Salzburg, Austria",
    path: "/salzburgRosenheim"
  },{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/salzburgMuhldorf.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/Austria/salzburgMuhldorf.webp",
    title: "Salzburg - Mühldorf",
    info: "Salzburg, Austria - Mühldorf, Germany",
    path: "/salzburgMuhldorf"
  },{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/giselabahn.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/Austria/giselabahn.webp",
    title: "Giselabahn",
    info: "Saalfelden, Austria - Wörgl, Austria",
    path: "/giselabahnSaalfeldenWorgl"
  },
  // {
  //   image: "https://tswassets.blob.core.windows.net/tsc/tileImages/karawankenbahn.jpg",
  //   webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/Austria/karawankenbahn.webp",
  //   title: "Karawankenbahn: Ljubljana, Villach & Tarvisio",
  //   info: "",
  //   path: "/karawankenbahn"
  // },
  {
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/Tauernbahn.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/Austria/Tauernbahn.webp",
    title: "Tauernbahn: Schwarzach-Sankt Veit - Spittal an der Drau",
    info: "",
    path: "/tauernbahn"
  }
];

export default austriaData;
