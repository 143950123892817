const unitedKingdomData = [
  {
    image: "https://cdn.akamai.steamstatic.com/steam/apps/1922049/header.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/UnitedKingdom/huddersfieldLine.webp",
    title: "Huddersfield Line",
    info: "Manchester, UK - Leeds, UK",
    path: "/huddersfieldLine"
  },{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/midlandMainline.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/UnitedKingdom/midlandMainline.webp",
    title: "Midland Mainline",
    info: "Nottingham, UK - Lincoln, UK",
    path: "/midlandMainline"
  },{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/glasgowSubway.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/UnitedKingdom/glasgowSubway.webp",
    title: "Glasgow Subway",
    info: "Glasgow, Scotland",
    path: "/glasgowSubway"
  },{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/chathamMainLine.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/UnitedKingdom/chathamMainLine.webp",
    title: "Chatham Mainline",
    info: "London Victoria & Blackfriars - Dover & Ramsgate",
    path: "/chathamMainLine"
  },{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/southwesternExpressways.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/UnitedKingdom/southwesternExpressways.webp",
    title: "Southwestern Expressways",
    info: "Reading, UK - Exter, UK",
    path: "/southwesternExpressways"
  },{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/northWalesCoastLine.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/UnitedKingdom/northWalesCoastLine.webp",
    title: "North Wales Coast Line",
    info: "Crewe, UK - Holyhead, UK",
    path: "/northWalesCoastLine"
  },
  {
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/birminghamCrossCity.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/UnitedKingdom/birminghamCrossCity.webp",
    title: "Birmingham Cross City",
    info: "Lichfield Trent Valley, UK - Bromsgrove & Redditch, UK",
    path: "/birminghamCrossCity"
  },
  {
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/suburbanGlasgowAirdrie.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/UnitedKingdom/suburbanGlasgowAirdrie.webp",
    title: "Suburban Glasgow: Airdrie",
    info: "Helensburgh, Scotland - Airdrie, Scotland",
    path: "/suburbanGlasgowAirdrie"
  },
  // {
  //   image: "https://tswassets.blob.core.windows.net/tsc/tileImages/cornishMainLine.jpg",
  //   webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/UnitedKingdom/cornishMainLine.webp",
  //   title: "Cornish Main Line",
  //   info: "Plymouth UK - Penzance, UK",
  //   path: "/cornishMainLine"
  // },
  {
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/midlandMainLineLeicesterDerbyNottingham.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/UnitedKingdom/midlandMainLineLeicesterDerbyNottingham.webp",
    title: "Midland Main Line",
    info: "Leicester, UK - Derby, UK - Nottingham, UK",
    path: "/midlandMainLineLeicesterDerbyNottingham"
  },
  // {
  //   image: "https://tswassets.blob.core.windows.net/tsc/tileImages/midlandMainLineScheffieldDerby.jpg",
  //   webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/UnitedKingdom/midlandMainLineScheffieldDerby.webp",
  //   title: "Midland Main Line",
  //   info: "Scheffield, UK - Derby, UK",
  //   path: "/midlandMainLineScheffieldDerby"
  // },
  {
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/southDevonMainLine.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/UnitedKingdom/southDevonMainLine.webp",
    title: "South Devon Main Line",
    info: "Highbridge & Burnham - Plymouth, UK",
    path: "/southDevonMainLine"
  }
  // {
  //   image: "https://tswassets.blob.core.windows.net/tsc/tileImages/southWalesCoastal.jpg",
  //   webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/UnitedKingdom/southWalesCoastal.webp",
  //   title: "South Wales Coastal",
  //   info: "Bristol, Wales - Swansea, Wales",
  //   path: "/southWalesCoastal"
  // },
  // {
  //   image: "https://tswassets.blob.core.windows.net/tsc/tileImages/suburbanGlasgowNorthwest.jpg",
  //   webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/UnitedKingdom/suburbanGlasgowNorthwest.webp",
  //   title: "Suburban Glasgow Northwest",
  //   info: "Glasgow, Scotland - Helensburgh, Scotland",
  //   path: "/suburbanGlasgowNorthwest"
  // }
];

export default unitedKingdomData;
