const germanyData = [
  {
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/pegnitztalbahn.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/Germany/pegnitztalbahn.webp",
    title: "Pegnitztalbahn",
    info: "Nürnberg, Germany - Bayreuth, Germany",
    path: "/pegnitztalbahn"
  },{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/holzkirchenW%C3%B6rgl.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/Germany/holzkirchenW%C3%B6rgl.webp",
    title: "Holzkirchen - Wörgl",
    info: "Holzkirchen, Germany - Wörgl, Austria",
    path: "/holzkirchenWorgl",
  },{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/salzburgRosenheim.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/Germany/salzburgRosenheim.webp",
    title: "Salzburg - Rosenheim",
    info: "Rosenheim, Germany - Salzburg, Austria",
    path: "/salzburgRosenheim"
  },{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/salzburgMuhldorf.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/Germany/salzburgMuhldorf.webp",
    title: "Salzburg - Mühldorf",
    info: "Salzburg, Austria - Mühldorf, Germany",
    path: "/salzburgMuhldorf"
  },{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/strasbourgKarlsruhe.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/Germany/strasbourgKarlsruhe.webp",
    title: "Bahnstrecke Strasbourg",
    info: "Strasbourg, France - Karlsruhe, Germany",
    path: "/bahnstreckeStrasbourgKarlsruhe"
  }
  ,{
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/bahnstreckeRiesaDresden.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/Germany/bahnstreckeRiesaDresden.webp",
    title: "Bahnstrecke Riesa - Dresden",
    info: "Riesa, Germany - Dresden, Germany",
    path: "/bahnstreckeRiesaDresden"
  }
  // ,{
  //   image: "https://tswassets.blob.core.windows.net/tsc/tileImages/heartOfBerlin.jpg",
  //   webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/Germany/heartOfBerlin.webp",
  //   title: "Heart of Berlin",
  //   info: "Hennigsdorf - Teltow, Berlin, Germany",
  //   path: "/heartOfBerlin"
  // }
];

export default germanyData;
