const chinaData = [
  {
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/chengduSuiningHighSpeed.png",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/China/chengduSuiningHighSpeed.webp",
    title: "Chengdu - Suining High Speed",
    info: "Chengdu - Suining, Sechuan, China",
    path: "/chengduSuiningHighSpeed"
  }
];

export default chinaData;
