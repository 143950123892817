/*
* This is the OLD LEGACY version of the map page. Swap out the "MapPage_in_progress.js" code
* to continue work on updated version of page.
*/
import { Component } from 'react';
import Header from '../app/header/Header';
import './Support.css';

class Support extends Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    
    return (
      <div className="rootContainer">
        <Header />
        <div className="supportContainer">
          <h1 className="supportHeader">Support TSW Maps</h1>
          <div className="support-text">I currently accept contributions via PayPal. While TSW Maps will always be open for everyone to use, sending a one time contribution helps cover hosting fees. Use the PayPal button below to make a donation.</div>
          <div className="support-text">Previous Donors (contribution amounts are cumulative):</div>
            <ul>
                <li className="support-list">Kelsey Ahlstrom: 10 USD</li>
            </ul>
            <br />
            <a href="https://paypal.me/tswmaps"><button className="paypal-button" type="submit">Send Contribution via PayPal</button></a>
        </div>
      </div>
    );
  }
}

export default Support;
