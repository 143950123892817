import { Component } from 'react';
import Collapsible from 'react-collapsible';
import './MapPage.css';

class MapAbout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            routeInfo: []
          };
    }

    render() {
        return(
            <div style={{ textAlign: "center", marginRight: '18px', marginLeft: '18px', marginBottom: '3px', fontWeight: "bold" }}>
                <Collapsible trigger="About" open={true}>
                    <div className="aboutText" style={{ textAlign: "left", paddingLeft: "5px", paddingBottom: "14px", fontSize: "16px" }}>{this.props.info.description}</div>
                </Collapsible>
                <Collapsible trigger="Route" open={true}>
                    {this.props.info.routeInfo.map(function (value, index, array) {
                            return <div className="aboutText" key="index" style={{ textAlign: "left", paddingLeft: "5px", paddingBottom: "14px", fontSize: "16px" }}>{value}</div>
                        })
                    }
                </Collapsible>
                <Collapsible trigger="Details" open={true}>
                <table>
                    <tr>
                        <td className='ree'>Length</td>
                        <td className='ree2'>{this.props.info.length}</td>
                    </tr>
                    <tr>
                        <td className='ree'>Country</td>
                        <td className='ree2'>{this.props.info.country}</td>
                    </tr>
                    <tr>
                        <td className='ree'>Operator(s)</td>
                        <td className='ree2'>{this.props.info.operators}</td>
                    </tr>
                    <tr>
                        <td className='ree'>Release</td>
                        <td className='ree2'>{this.props.info.releaseDate}</td>
                    </tr>
                    <tr>
                        <td className='ree'>Designer</td>
                        <td className='ree2'>{this.props.info.designer}</td>
                    </tr>
                    <tr>
                        <td className='ree'>Signal System</td>
                        <td className='ree2'>{this.props.info.signalSystem}</td>
                    </tr>
                    <tr>
                        <td className='ree'>Locomotives</td>
                        <td className='ree2'>{this.props.info.locomotives}</td>
                    </tr>
                </table> 
                </Collapsible>
                {/* <div className="attribution">
                    This article uses material from the <a className="attributionLink" href="https://train-sim-world.fandom.com/wiki/Southeastern_High_Speed_Extended" target="_blank">"Southeastern High Speed Extended" article</a> on the <a className="attributionLink" href="https://train-sim-world.fandom.com/wiki/Train_Sim_World_Wiki" target="_blank">Train Sim World wiki</a> at <a className="attributionLink" href="https://www.fandom.com/" target="_blank">Fandom</a> and is licensed under the <a href="https://creativecommons.org/licenses/by-sa/3.0/">Creative Commons Attribution-Share Alike License</a>.
                </div> */}
              </div>
        );
    }
}

export default MapAbout;