import React, { Component } from 'react';
import styles from "./EmailSignupForm.module.css";
import axios from "axios";

class EmailSignupForm extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    showMessage: false
  };/* This is where the magic happens */

  handleSubmit = async (event) => {
    event.preventDefault();
    const user = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email
    }
    await axios.post(`https://tsw-maps-functions.azurewebsites.net/api/EnrollEmail?code=gOF6hQBe7u4FdLcoL3r3iqjVSLvNfXsLJWs1ild9mWjDAzFuZmgkUw==&firstName=user&lastName=TSC&email=${this.state.email}`)
      .then(res=>{
        console.log(res);
        console.log(res.data);
        // TODO: Setup a thank you for signing up page!
        // window.location = "/" //This line of code will redirect you once the submission is succeed
      });
    this.setState({ showMessage: true });
  }
  
  handleChangeFirstName = event =>{
    this.setState({ firstName: event.target.value});
  }
  handleChangeLastName = event =>{
    this.setState({ lastName: event.target.value});
  }
  handleChangeEmail = event =>{
    this.setState({ email: event.target.value});
  }

  render() {
    let message;
    if (this.state.showMessage) {
      message = <div>Thank you for joining!</div>;
    } else { 
      message = <div></div>;
    }

    return (
      <div>
      <div className="bg-gray py-6 w-full text-center text-white" >
        <h3 className="font-bold text-[1.2rem] tracking-wide">
          JOIN OUR MAILING LIST
        </h3>
        <div>Be the first to know when new content and features come to TSWMaps!</div>
        <form onSubmit={this.handleSubmit}>
          <label>Email
            <input className={styles.inputText} type="text" name="email" onChange={this.handleChangeEmail} />
          </label>
          {message}
          <button className={styles.submitButton} type="submit">SUBMIT</button>
        </form>
      </div>
      <div className="h-1 w-full bg-gradient-to-r from-blue to-navy" />
      </div>
    );
  }
}
export default EmailSignupForm;