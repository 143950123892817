import React from "react";
import styles from "./UnitedKingdom.module.css";
import unitedKingdomData from "./unitedKingdomData";
import TrainCard from "../../component/trainCard/TrainCard";
import Masonry from "react-masonry-css";
import { Link } from 'react-router-dom';

const UnitedKingdom = () => {
  const breakpoints = {
    default: 6,
    2368: 5,
    1977: 4,
    1591: 3,
    1209: 2,
    819: 1,
  };

  return (
    <div>
      {/* header Section */}
      <div className="bg-gray py-12 w-full text-center text-white">
        {/* Header */}
        <h3 className="font-bold text-[1.2rem] tracking-wide">
          LEGACY DLC ROUTES
        </h3>
        {/* Subheading */}
        <div className="w-[80%] mx-auto mt-2">
          <p className={styles.infoText}>
            Explore more than a decade of rail roads features throughout the Train Simulator Classic franchise.
          </p>
          <p className={styles.infoText}>
            MORE COMING SOON!
          </p>
        </div>
      </div>

      {/* Trains Section */}
      <div className={styles.trainSectionContainer}>
        <div className="p-10">
          {/* Heading Location */}
          <div>
            <h2
              className={`text-[2.5rem] text-white font-bold drop-shadow-lg ${styles.titleText}`}
            >
              UNITED KINGDOM
            </h2>
          </div>

          {/* Trains */}
          <Masonry
            breakpointCols={breakpoints}
            className={styles.masnoryGrid}
            columnClassName={styles.masnoryGridColumn}
          >
            {unitedKingdomData.map((trainData, i) => {
              return (
                <Link to={trainData.path}>
                <div key={i} className="w-[90%] mx-auto flex justify-center">
                  <TrainCard
                    image={trainData.image}
                    webpImage={trainData.webpImage}
                    title={trainData.title}
                    info={trainData.info}
                  />
                </div>
                </Link>
              );
            })}
          </Masonry>
        </div>
      </div>
    </div>
  );
};

export default UnitedKingdom;
