import React from "react";
import styles from "./TrainCard.module.css";

const TrainCard = ({ title, image, webpImage, info }) => {
  return (
    <div className="bg-gray p-2 w-[95%] h-[200px] mx-auto md:mx-0  md:w-[370px] md:h-[255px] mt-10 border-[0.5px] border-[#ffffff51]">
      {/* Image */}
      <div>
        <picture>
          <source srcSet={webpImage} type="image/webp" alt={title} loading="lazy" />
          <img src={image} alt={title} loading="lazy" />
        </picture>
      </div>

      {/* Title and information */}
      <div className="text-center text-white mt-2">
        <h4 className={styles.titleText}>{title}</h4>
        <p className={`${styles.infoText}`}>{info}</p>
      </div>
    </div>
  );
};

export default TrainCard;
