
import { Component } from 'react';
import { 
    Map,
    GoogleApiWrapper,
    Polyline
    } from "google-maps-react";
import Header from '../app/header/Header';
import MapAbout from './MapAbout';
import './MapPage.css'; 

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {color: '#041D3E'};
  }
  
  componentDidMount() {
    document.title = `TSC Maps | ${this.props.data.name}`;
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const data = this.props.data;

    let routeArray = [];
    data.routeDetails.features.forEach((feature) => {
      let coordObjects = [];
      feature.geometry.coordinates.forEach((coordPair) => {
        coordObjects.push({"lng": coordPair[0], "lat": coordPair[1]});
      });
      routeArray.push({ "name": `${feature.properties.name} | ${coordObjects[0].lat}, ${coordObjects[0].lng}`, "route": coordObjects });
    });

    // Adding an ID attribute to the route objects we can use as key in the <Polylines/> below.
    let i = 0;
    let newArr = [];
    routeArray.forEach((obj) => {
      let temp = obj;
      temp.id = i;
      newArr.push(temp);
      i += 1;
    });
    routeArray = newArr;

    const routeCoords = routeArray.map((route) =>
      <Polyline
          path={route.route}
          strokeColor="#c82828"
          strokeOpacity={0.8}
          strokeWeight={3}
          key={route.id}
      />
    );

    const icon = {
      path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
      fillColor: this.state.color,
      fillOpacity: 0.9,
      strokeWeight: 0,
      rotation: 0,
      scale: 0.9,
      anchor: new window.google.maps.Point(15, 30),
    };

    return (
      <div className="parentContainer">
        <Header />
        <div className="childContainer1" >
            <div className="routeInfoParent">
              <div className="routeInfoChild1">{data.name}</div>
              <div className="routeInfoChild2">{data.location}</div>
              <MapAbout routeId={data.id} info={data.routeFacts} />
            </div>
        </div>
        <div className="childContainer2">
            <Map
              google={this.props.google}
              containerStyle={{ position: "relative", float: "right", height: "92.3vh" }}
              zoom={data.zoom}
              initialCenter={data.initialCenter}
            >
              {routeCoords}
            </Map>
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyB7r-J-m2N4U1HJgbim-ee_1og_xdz6a7w",
})(App);
