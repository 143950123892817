import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import MapPage from './component/MapPage';
import Support from './component/Support';

// TSC route data files
const wcmlSouth = require('./data/mapData/tsc/wcmlSouth.json');
const tehachapiPass = require('./data/mapData/tsc/tehachapiPass.json');
const bahnstreckeLeipzigDresdenExtended = require('./data/mapData/tsc/bahnstreckeLeipzigDresdenExtended.json');
const chengduSuiningHighSpeed = require('./data/mapData/tsc/chengduSuiningHighSpeed.json');
const huddersfieldLine = require('./data/mapData/tsc/huddersfieldLine.json');
const taurusMountains = require('./data/mapData/tsc/taurusMountains.json');
const drautalbahn = require('./data/mapData/tsc/drautalbahn.json');
const holzkirchenWorgl = require('./data/mapData/tsc/holzkirchenWorgl.json');
const longIslandRailRoad = require('./data/mapData/tsc/longIslandRailroad.json');
const midlandMainline = require('./data/mapData/tsc/midlandMainline.json');
const glasgowSubway = require('./data/mapData/tsc/glasgowSubway.json');
const soldierSummit = require('./data/mapData/tsc/soldierSummit.json');
const salzburgSchwarzachStVeit = require('./data/mapData/tsc/salzburgSchwarzachStVeit.json');
const tohokuHighSpeed = require('./data/mapData/tsc/tohokuHighSpeed.json');
const salzburgRosenheim = require('./data/mapData/tsc/salzburgRosenheim.json');
const mountShastaLine = require('./data/mapData/tsc/mountShastaLine.json');
const northWalesCoastLine = require('./data/mapData/tsc/northWalesCoastLine.json');
const chathamMainLine = require('./data/mapData/tsc/chathamMainLine.json');
const salzburgMuhldorf = require('./data/mapData/tsc/salzburgMuhldorf.json');
const bahnstreckeStrasbourgKarlsruhe = require('./data/mapData/tsc/bahnstreckeStrasbourgKarlsruhe.json');
const salzburgWels = require('./data/mapData/tsc/salzburgWels.json');
const mountainSubdivisionBnO = require('./data/mapData/tsc/mountainSubdivisionBnO.json');
const southwesternExpressways = require('./data/mapData/tsc/southwesternExpressways.json');
const arizonaDivide = require('./data/mapData/tsc/arizonaDivide.json');
const washingtonDcNec = require('./data/mapData/tsc/washingtonDcNec.json');
const birminghamCrossCity = require('./data/mapData/tsc/birminghamCrossCity.json');
const cornishMainLine = require('./data/mapData/tsc/cornishMainLine.json');
const midlandMainLineLeicesterDerbyNottingham = require('./data/mapData/tsc/midlandMainLineLeicesterDerbyNottingham.json');
const midlandMainLineScheffieldDerby = require('./data/mapData/tsc/midlandMainLineScheffieldDerby.json');
const southDevonMainLine = require('./data/mapData/tsc/southDevonMainLine.json');
const southWalesCoastal = require('./data/mapData/tsc/southWalesCoastal.json');
const suburbanGlasgowNorthwest = require('./data/mapData/tsc/suburbanGlasgowNorthwest.json');
const suburbanGlasgowAirdrie = require('./data/mapData/tsc/suburbanGlasgowAirdrie.json');
const bahnstreckeRiesaDresden = require('./data/mapData/tsc/bahnstreckeRiesaDresden.json');
const giselabahnSaalfeldenWorgl = require('./data/mapData/tsc/giselabahnSaalfeldenWorgl.json');
const heartOfBerlin = require('./data/mapData/tsc/heartOfBerlin.json');
const karawankenbahn = require('./data/mapData/tsc/karawankenbahn.json');
const tauernbahn = require('./data/mapData/tsc/tauernbahn.json');
const pegnitztalbahn = require('./data/mapData/tsc/pegnitztalbahn.json');
const bostonAlbanySpringfield = require('./data/mapData/tsc/bostonAlbanySpringfield.json');
const tokyoCommuterKeihinTohokuUtsunomiya = require('./data/mapData/tsc/tokyoCommuterKeihinTohokuUtsunomiya.json');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <Routes>
        {/* root */}
        <Route path='/' element={<App />} />
        
        {/* Support Page */}
        <Route path='/support' element={<Support />} />

        {/* TSC Routes */}
        <Route path='/wcmlSouth' element={<MapPage data={wcmlSouth} /> } />
        <Route path='/tehachapiPass' element={<MapPage data={tehachapiPass} /> } />
        <Route path='/bahnstreckeLeipzigDresdenExtended' element={<MapPage data={bahnstreckeLeipzigDresdenExtended} /> } />
        <Route path='/chengduSuiningHighSpeed' element={<MapPage data={chengduSuiningHighSpeed} /> } />
        <Route path='/huddersfieldLine' element={<MapPage data={huddersfieldLine} /> } />
        <Route path='/taurusMountains' element={<MapPage data={taurusMountains} /> } />
        <Route path='/drautalbahn' element={<MapPage data={drautalbahn} /> } />
        <Route path='/holzkirchenWorgl' element={<MapPage data={holzkirchenWorgl} /> } />
        <Route path='/longIslandRailroad' element={<MapPage data={longIslandRailRoad} /> } />
        <Route path='/midlandMainline' element={<MapPage data={midlandMainline} /> } />
        <Route path='/glasgowSubway' element={<MapPage data={glasgowSubway} /> } />
        <Route path='/soldierSummit' element={<MapPage data={soldierSummit} /> } />
        <Route path='/salzburgSchwarzachStVeit' element={<MapPage data={salzburgSchwarzachStVeit} /> } />
        <Route path='/tohokuHighSpeed' element={<MapPage data={tohokuHighSpeed} /> } />
        <Route path='/salzburgRosenheim' element={<MapPage data={salzburgRosenheim} /> } />
        <Route path='/mountShastaLine' element={<MapPage data={mountShastaLine} /> } />
        <Route path='/northWalesCoastLine' element={<MapPage data={northWalesCoastLine} /> } />
        <Route path='/chathamMainLine' element={<MapPage data={chathamMainLine} /> } />
        <Route path='/salzburgMuhldorf' element={<MapPage data={salzburgMuhldorf} /> } />
        <Route path='/bahnstreckeStrasbourgKarlsruhe' element={<MapPage data={bahnstreckeStrasbourgKarlsruhe} /> } />
        <Route path='/salzburgWels' element={<MapPage data={salzburgWels} /> } />
        <Route path='/mountainSubdivisionBnO' element={<MapPage data={mountainSubdivisionBnO} /> } />
        <Route path='/southwesternExpressways' element={<MapPage data={southwesternExpressways} /> } />
        <Route path='/arizonaDivide' element={<MapPage data={arizonaDivide} /> } />
        <Route path='/washingtonDcNec' element={<MapPage data={washingtonDcNec} /> } />
        <Route path='/birminghamCrossCity' element={<MapPage data={birminghamCrossCity} /> } />
        <Route path='/cornishMainLine' element={<MapPage data={cornishMainLine} /> } />
        <Route path='/midlandMainLineLeicesterDerbyNottingham' element={<MapPage data={midlandMainLineLeicesterDerbyNottingham} /> } />
        <Route path='/midlandMainLineScheffieldDerby' element={<MapPage data={midlandMainLineScheffieldDerby} /> } />
        <Route path='/southDevonMainLine' element={<MapPage data={southDevonMainLine} /> } />
        <Route path='/southWalesCoastal' element={<MapPage data={southWalesCoastal} /> } />
        <Route path='/suburbanGlasgowNorthwest' element={<MapPage data={suburbanGlasgowNorthwest} /> } />
        <Route path='/suburbanGlasgowAirdrie' element={<MapPage data={suburbanGlasgowAirdrie} /> } />
        <Route path='/bahnstreckeRiesaDresden' element={<MapPage data={bahnstreckeRiesaDresden} /> } />
        <Route path='/giselabahnSaalfeldenWorgl' element={<MapPage data={giselabahnSaalfeldenWorgl} /> } />
        <Route path='/heartOfBerlin' element={<MapPage data={heartOfBerlin} /> } />
        <Route path='/karawankenbahn' element={<MapPage data={karawankenbahn} /> } />
        <Route path='/tauernbahn' element={<MapPage data={tauernbahn} /> } />
        <Route path='/pegnitztalbahn' element={<MapPage data={pegnitztalbahn} /> } />
        <Route path='/bostonAlbanySpringfield' element={<MapPage data={bostonAlbanySpringfield} /> } />
        <Route path='/tokyoCommuterKeihinTohokuUtsunomiya' element={<MapPage data={tokyoCommuterKeihinTohokuUtsunomiya} /> } />
      </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
