import React from "react";
import styles from "./Japan.module.css";
import japanData from "./japanData";
import TrainCard from "../../component/trainCard/TrainCard";
import Masonry from "react-masonry-css";
import { Link } from 'react-router-dom';

const Japan = () => {
  const breakpoints = {
    default: 6,
    2368: 5,
    1977: 4,
    1591: 3,
    1209: 2,
    819: 1,
  };

  return (
    <div>
      {/* Trains Section */}
      <div className={styles.trainSectionContainer}>
        <div className="p-10">
          {/* Heading Location */}
          <div>
            <h2
              className={`text-[2.5rem] text-white font-bold drop-shadow-lg ${styles.titleText}`}
            >
              JAPAN
            </h2>
          </div>

          {/* Trains */}
          <Masonry
            breakpointCols={breakpoints}
            className={styles.masnoryGrid}
            columnClassName={styles.masnoryGridColumn}
          >
            {japanData.map((trainData, i) => {
              return (
                <Link to={trainData.path}>
                  <div key={i} className="w-[90%] mx-auto flex justify-center">
                    <TrainCard
                      image={trainData.image}
                      webpImage={trainData.webpImage}
                      title={trainData.title}
                      info={trainData.info}
                    />
                  </div>
                </Link>
              );
            })}
          </Masonry>
        </div>
      </div>
    </div>
  );
};

export default Japan;
