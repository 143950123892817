const franceData = [
  {
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/strasbourgKarlsruhe.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/France/strasbourgKarlsruhe.webp",
    title: "Bahnstrecke Strasbourg",
    info: "Strasbourg, France - Karlsruhe, Germany",
    path: "/bahnstreckeStrasbourgKarlsruhe"
  },
];

export default franceData;
