import React from "react";
import styles from "./ExploreSection.module.css";
import { Link } from 'react-router-dom';

const trainData = [
  {
    name: "BAHNSTRECKE LEIPZIG - DRESDEN",
    description:
      "The new Leipzig - Riesa route extension adds an additional 65 km to the popular Riesa - Dresden route, making it a whole new experience as you drive the full 120km Bahnstrecke Leipzig - Dresden, as well as through the modern Leipzig City Tunnel!",
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/bahnstreckeLeipzigDresden.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/exploreTiles/bahnstreckeLeipzigDresden.webp",
    path: "/bahnstreckeLeipzigDresdenExtended"
  },
  {
    name: "WCML SOUTH: LONDON - BIRMINGHAM NEW STREET",
    description:
      "Take to the tracks of one of the most important railway corridors in the UK, and operate a mix of express, stopper, commuter and freight services with Train Simulator’s WCML South: London Euston – Birmingham route!",
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/wcmlSouth.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/exploreTiles/wcmlSouth.webp",
    path: "/wcmlSouth"
  },
  {
    name: "TEHACHAPI PASS: MOJAVE - BAKERSFIELD",
    description:
      "Take on the challenge of operating a train along the Tehachapi Pass, a legendary route that crosses the rugged mountains and desert landscapes of California.",
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/tehachapiPass.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/exploreTiles/tehachapiPass.webp",
    path: "/tehachapiPass"
  }
];

const breakpoints = {
  default: 4,
  1618: 3,
  1238: 2,
  831: 1,
};

const ExploreSection = () => {
  return (
    <div>
      <div className="bg-gray  w-[100vw] pt-10 pb-16 px-10">
        {/* Header */}
        <div>
          <h3 className="text-white font-bold text-[1.2rem] text-center tracking-wide">
            EXPLORE THE RAIL LINES OF
            <span className="text-red"> TRAIN SIMULATOR CLASSIC</span>
          </h3>
        </div>

        <div className="flex flex-wrap justify-center md:space-x-5">
          {trainData.map((data, i) => {
            return (
              <Link to={data.path}>
              <div
                key={i}
                // className={`w-[90%] mx-auto md:w-[400px] mt-10`}
                className={`w-[400px] mt-10`}
              >
                {/* Image */}
                <div className="flex justify-center w-full">
                  <picture>
                    <source srcSet={data.webpImage} type="image/webp" alt={data.name} />
                    <img src={data.image} alt={data.name} />
                  </picture>
                </div>

                {/* Name and description */}
                <div className="mt-3 text-center text-white">
                    <h3 className={`text-[1.5rem] ${styles.titleText}`}>
                      {data.name}
                    </h3>
                  <p className={`${styles.infoText}`}>{data.description}</p>
                </div>
              </div>
              </Link>
            );
          })}
        </div>
      </div>
      <div className="h-1 w-full bg-gradient-to-r from-blue to-navy" />
    </div>
  );
};

export default ExploreSection;
