const franceData = [
  {
    image: "https://tswassets.blob.core.windows.net/tsc/tileImages/taurusMountains.jpg",
    webpImage: "https://tswassets.blob.core.windows.net/tsc/tiles/Turkey/taurusMountains.webp",
    title: "Taurus Mountains",
    info: "Ulukışla – Yenice, Turkey",
    path: "/taurusMountains"
  },
];

export default franceData;
