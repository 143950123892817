import React from "react";
import { Link } from 'react-router-dom';
import "./Header.css";

const Header = () => {
  return (
    <div className="sticky top-0 z-50">
      <div className="bg-black px-10 py-2 flex items-center">
        {/* Logo */}
        <div>
          <Link to="/">
            <img
              className="h-[50px]"
              src="/logo/tscmaps_logo.png"
              alt="Tsw Logo"
            />
          </Link>
        </div>

        {/* Routes */}
        <div className="text-white flex font-bold items-center ml-10 space-x-10">
          <Link to="/">
            <h3 className="headerLink">Home</h3>
          </Link>
          <a href="https://www.tswmaps.com/">
            <h3 className="headerLink">TSW 3 Maps</h3>
          </a>
          <Link to="/support">
            <h3 className="headerLink">Support</h3>
          </Link>
        </div>
      </div>
      <div className="h-1 w-full bg-gradient-to-r from-blue to-navy" />
    </div>
  );
};

export default Header;
